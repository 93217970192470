export default function Checkbox({ className = "", ...props }) {
    return (
        <input
            {...props}
            type="checkbox"
            className={
                "bg-island-spice rounded border-gray-300 text-astronaut-blue shadow-sm focus:ring-astronaut-blue " +
                className
            }
        />
    );
}
