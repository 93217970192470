import { useEffect } from "react";
import Checkbox from "../../components/Checkbox";
import GuestLayout from "../../Layouts/GuestLayout";
import InputError from "../../components/InputError";
import AuthButton from "../../components/UI/AuthButton";
import FormTextInputSecondary from "../../components/Form/FormTextInputSecondary";
import { Head, Link, useForm } from "@inertiajs/react";

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route("login"));
    };

    const handleGoogleLoginClick = () => {
        window.location.href = route("login.google");
    };

    return (
        <GuestLayout>
            <Head title="Log in" />

            <div className="my-12 mx-10">
                <h1 className="text-3xl font-medium text-astronaut-blue">
                    Log In to Your Account
                </h1>

                {status && (
                    <div className="mb-4 font-medium text-base text-green-600">
                        {status}
                    </div>
                )}

                <div className="mt-6">
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-white font-medium py-1 px-4 text-lg rounded-lg focus:outline-none focus:ring-0 shadow-xl w-full hover:bg-gray-100"
                            onClick={handleGoogleLoginClick}
                        >
                            <img
                                src="/images/google.svg"
                                alt="Google"
                                className="w-4 h-4 inline-block mr-2"
                            />
                            Log In with Google
                        </button>
                    </div>
                </div>

                <div className="mt-6 w-full text-center text-gray-500">
                    - OR -
                </div>

                <form onSubmit={submit}>
                    <div className="mt-4">
                        <FormTextInputSecondary
                            id="email"
                            type="email"
                            name="email"
                            label="Email"
                            placeholder="Your email"
                            value={data.email}
                            className="block w-full"
                            autoComplete="username"
                            isFocused={true}
                            onChange={(value) => setData("email", value)}
                        />

                        <InputError message={errors.email} className="mt-2" />
                    </div>

                    <div className="mt-6">
                        <FormTextInputSecondary
                            id="password"
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="Your password"
                            value={data.password}
                            className="block w-full"
                            autoComplete="current-password"
                            onChange={(value) => setData("password", value)}
                        />

                        <InputError
                            message={errors.password}
                            className="mt-2"
                        />
                    </div>

                    <div className="block mt-4">
                        <label className="flex items-center">
                            <Checkbox
                                name="remember"
                                checked={data.remember}
                                onChange={(e) =>
                                    setData("remember", e.target.checked)
                                }
                            />
                            <span className="ml-2 text-sm text-gray-600 cursor-pointer">
                                Remember me
                            </span>
                        </label>
                    </div>

                    <div className="mt-6">
                        <AuthButton disabled={processing}>Log In</AuthButton>
                    </div>
                    <div className="mt-2">
                        <Link
                            href={route("password.request")}
                            className="underline text-base text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Forgot your password?
                        </Link>
                    </div>
                    <div>
                        <Link
                            href={route("register")}
                            className="underline text-base text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Create a New Account
                        </Link>
                    </div>
                </form>
            </div>
        </GuestLayout>
    );
}
